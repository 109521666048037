import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "input"];

  connect() {
    this.toggleSubmitDisable();
  }

  change() {
    this.toggleSubmitDisable();
  }

  toggleSubmitDisable() {
    this.submitTarget.disabled = this.allInputsIsEmpty();
  }

  allInputsIsEmpty() {
    return this.inputTargets.filter(i => i.value).length == 0;
  }
}
