import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"];

  toggle(event) {
    const clickedContent = event.currentTarget.parentElement;

    this.contentTargets.map((item) => {
      if (item !== clickedContent && item.classList.contains("accordion__item-active")) {
        item.classList.remove('accordion__item-active');
      }
    });

    clickedContent.classList.toggle('accordion__item-active');
  }
}
