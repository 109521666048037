import { registerControllers } from 'stimulus-vite-helpers'
import Autosave from 'stimulus-rails-autosave'
import Reveal from 'stimulus-reveal-controller'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import { Alert, Dropdown, Modal } from 'tailwindcss-stimulus-components'
import Popover from '@stimulus-components/popover'

import { stimulus } from '~/init'

stimulus.register('alert', Alert)
stimulus.register('autosave', Autosave)
stimulus.register('dropdown', Dropdown)
stimulus.register('reveal', Reveal)
stimulus.register('autogrow', TextareaAutogrow)
stimulus.register('popover', Popover)
stimulus.register('syncmodal', Modal)

const controllers = import.meta.glob('@/controllers/**/*_controller.js', { eager: true })
registerControllers(stimulus, controllers)
