import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["video"];

  escClose(event) {
    if (event.key === 'Escape') this.close();
  }

  open() {
    this.element.classList.remove("hidden")
  }

  close() {
    this.element.classList.add("hidden")

    if (this.hasVideoTarget) {
      this.videoTarget.removeAttribute('src');
    }
  }

  submitEnd(event) {
    if (event.detail && event.detail.success) {
      this.close();
    }
  }
}
