import { Controller } from '@hotwired/stimulus'
import JSShare from 'js-share'
import Toastify from 'toastify-js'

export default class extends Controller {
  connect () {
    JSShare.options.url = 'http://raisify.co/'
  }

  share (event) {
    event.preventDefault()
    const text = this.shareTitle(event.currentTarget)
    const target = event.currentTarget
    target.setAttribute('data-title', text)

    JSShare.go(target)
  }

  copyToClipboard (event) {
    const title = this.shareTitle(event.currentTarget)

    navigator.clipboard.writeText(`${title} ${window.location.href}`)

    Toastify({
      text: 'Copied to clipboard',
      duration: 2000,
      style: { borderRadius: '8px', fontSize: '16px', background: '#00A87D' }
    }).showToast()
  }

  shareTitle (target) {
    const name = target.getAttribute('data-name')

    return `Check out ${name}'s profile on Raisify!`
  }
}
