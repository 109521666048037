import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie';

export default class extends Controller {
  static outlets = ["modal"];

  connect() {
    const firstTime = Cookies.get('firstTime');

    if(!firstTime) {
      Cookies.set('firstTime', true)

      this.modalTimeout = setTimeout(() => {
        this.element.click()
      }, '500');
    }
  }
}
