import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["limitModal"];

  connect() {
    this.modal = this.element;
    this.minValue = 5;
    this.currentInput = null;
  }

  valueChange(event) {
    event.currentTarget.dispatchEvent(new CustomEvent("changed"));
    event.currentTarget.dispatchEvent(new Event("change"));
  }

  checkValue(event) {
    const value = (event.currentTarget.value || "").replace('$','');

    if (value && value < this.minValue) {
      event.preventDefault();
      this.currentInput = event.currentTarget;
      this.showLimitModal();
    }
  }

  showLimitModal() {
    this.limitModalTarget.classList.remove("hidden");
  }

  hideLimitModal() {
    this.limitModalTarget.classList.add("hidden");
  }

  updateValueToMin() {
    this.currentInput.focus();
    this.currentInput.value = this.minValue;
    this.currentInput.blur();
    this.hideLimitModal();
    this.currentInput.dispatchEvent(new CustomEvent("changed"));
    this.currentInput.dispatchEvent(new Event("change"));
  }

  reset() {
    this.currentInput.focus();
    this.currentInput.value = '';
    this.currentInput.blur();
    this.hideLimitModal();
    this.currentInput.dispatchEvent(new CustomEvent("changed"));
    this.currentInput.dispatchEvent(new Event("change"));
  }
}
