import { Controller } from "@hotwired/stimulus"
import Toastify from 'toastify-js';

export default class extends Controller {
  static targets = ["submit", "button", "input"];

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    this.userId = this.element.getAttribute('data-user-id');
    this.donations = [];
    this.loginUrl = this.element.getAttribute('data-login-url');
    this.competitionId = this.element.getAttribute('data-competition-id');
    this.minValue = 5;
  }

  customValueChange(event) {
    const value = (event.target.value || "").replace('$','');
    const pitchId = event.target.getAttribute('pitch-id');

    this.unselectPitchDonates(pitchId);

    if (value) {
      this.addDonate(event.target, pitchId, value);
    } else {
      event.target.classList.remove('selected');
    }

    this.toggleSubmit();
  }

  addDonate(target, pitchId, value) {
    this.donations.push({id: pitchId, value: value});
    target.classList.add("selected");

    this.toggleSubmit();
  }

  priceSelect(event) {
    const target = event.target;

    if (target.classList.contains("selected")) {
      this.unseletDonate(target);
    } else {
      this.selectDonate(target);
    }

    this.toggleSubmit();
  }

  selectDonate(target) {
    const value = target.getAttribute('value');
    const pitchId = target.getAttribute('pitch-id');

    this.unselectPitchDonates(pitchId);
    this.addDonate(target, pitchId, value);
  }

  unselectPitchDonates(pitchId) {
    this.buttonTargets.filter((t) => t.getAttribute('pitch-id') == pitchId).forEach((i) => i.classList.remove("selected"));
    this.inputTargets.filter((t) => t.getAttribute('pitch-id') == pitchId).forEach((i) => i.classList.remove("selected"));
    this.donations = this.donations.filter((i) => i.id != pitchId);
  }

  unseletDonate(target) {
    const pitchId = target.getAttribute('pitch-id');
    this.donations = this.donations.filter((i) => i.id != pitchId);

    target.classList.remove("selected");
  }

  async checkout() {
    if (!this.userId) {
      window.location.href = this.loginUrl;
    }

    const response = fetch("/payments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(
        {
          user_id: this.userId,
          competition_id: this.competitionId,
          donations_attributes: this.donations.map(i => (
            { pitch_id: i.id, amount: parseFloat(i.value), user_id: this.userId }
          ))
        }
      ),
    });

    await response.then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response)
    }).then((data) => {
      window.location = `/payments/${data.id}/checkout`;
    }).catch((data) => this.showError('Error'));
  }

  toggleSubmit() {
    const value = this.totalDonateAmount();

    if (value && value >= this.minValue) {
      return this.enableSubmit();
    }

    return this.disableSubmit();
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.submitTarget.innerText = `Donate $${this.totalDonateAmount()}`
  }

  disableSubmit() {
    this.submitTarget.innerText = `Donate`
    this.submitTarget.disabled = true;
  }

  totalDonateAmount() {
    return this.donations.map((i) => i.value).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  }

  showError(text) {
    this.showToast(text, { background: "#f92f1d"} )
  }

  showToast(text, style) {
    Toastify({
      text: text,
      duration: 3000,
      close: true,
      style: style,
    }).showToast();
  }
}
