import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash.throttle'

export default class NavScroll extends Controller {
  static targets = ['navbar']

  connect () {
    this.lastKnownPos = 0
    this.scrollContainer = document.getElementById('body')
    this.scroll = throttle(this.scroll.bind(this), 400)
    this.scrollContainer.addEventListener('scroll', this.scroll)
  }

  getScrollPosition () {
    return this.scrollContainer.scrollTop
  }

  scroll () {
    if (this.getScrollPosition() > this.lastKnownPos) {
      this.navbarTarget.classList.remove('navbar-scroll')
    } else {
      this.navbarTarget.classList.add('navbar-scroll')
    }
    this.lastKnownPos = this.getScrollPosition()
    if (this.getScrollPosition() === 0) {
      this.navbarTarget.classList.remove('navbar-scroll')
    }
  }
}
