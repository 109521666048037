import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = [ "currency", "price", "percentage" ]

  connect() {
    if(this.hasCurrencyTarget) {
      this.mask = IMask(
        this.currencyTarget,
        {
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              scale: 0
            }
          }
        }
      );
    };

    if(this.hasPriceTarget) {
      this.mask = IMask(
        this.priceTarget,
        {
          mask: '$num',
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              thousandsSeparator: ',',
              radix: '.',
            }
          }
        }
      );
    };

    if(this.hasPercentageTarget) {
      this.mask = IMask(
        this.percentageTarget,
        { mask: [{
            mask: 'num%',
            blocks: {
              num: {
                mask: Number,
                min: 0,
                max: 100,
                radix: '.',
                mapToRadix: [','],
              },
            },
          }]
        }
      );
    };
  }

  disconnect() {
    this.mask?.destroy();
  }
}
